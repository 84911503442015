<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div id="container" class="landing" :style="{ backgroundImage: 'url(' + require('@/assets/img/skyline.svg') + ')' }">
        <div class="logo" />
        <img :src="logo" class="other-logo" />
        <div>
          <h1 class="slide-in-left">Willkommen bei</h1>
          <h1 class="slide-in-left">UKK <span class="font-face--brand">Aktuell</span></h1>
          <p class="fade-in subline text-color--lightgrey"><br>Bleiben Sie auf dem Laufenden:<br>In unserer App erhalten Sie alle News aus der Uniklinik Köln.</p>
        </div>

        <form class="fade-in">
          <button @click.prevent="$router.push('/')" class="btn-next guest" type="submit" form="login" value="Anmelden">
            <span>Ich bin Besucher / Patient</span>
          </button>
          <button @click.prevent="$router.push('/auth/login')" class="btn-next stuff" type="submit" form="login" value="Anmelden">
            <span>Ich bin Mitarbeiter</span>
          </button>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent } from '@ionic/vue';

export default  {
  name: 'Landing',
  components: { 
    IonContent, 
    IonPage 
  },
  data () {
    return {
      email: '',
      password: '',
      sent: false
    }
  },
  computed: {
    submitted (): boolean {
      return (this as any).sent;
    },
    logo () {
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return require(`@/assets/img/ukk-logo${!prefersDarkMode ? '' : '-white'}.svg`)
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave landing');
    (this as any).sent = false;
  },
  ionViewDidEnter () {
    console.log('[ionView] entered landing');
    localStorage.setItem('firstVisit', 'true');
  }
}
</script>

<style scoped lang="less">
.landing {
  .subline {
    font-size: 16px;
    line-height: 21px;
    margin: 15px 0;
  }
}
.logo {
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 50%;
  opacity: .866;
  margin: 50px 0 30px;
  animation-name: slide;
  animation-duration: 1.1s;
  animation-delay: .1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  transform: translate(1000px,0);
  height: 125px !important;
      max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.other-logo {
    position: absolute;
    width: 1000px;
    max-width: 1000px;
    right: -750px;
    top: 100px;
    opacity: .06;
}

.slide-in-left {
  animation-name: slideInLeft;
  animation-duration: .9s;
  animation-delay: .3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  transform: translate(-1000px,0);
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: .4s;
  animation-delay: 1.7s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  opacity: 0;
}

.guest {
  margin-bottom: 15px !important;
}

@keyframes slide {
  0%   { transform: translate(1000px,0); }
  100% { transform: translate(0px,0px); }
}

@keyframes slideInLeft {
  0%   { transform: translate(-1000px,0); }
  100% { transform: translate(0px,0px); }
}

@keyframes fadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.lost-password {
  font-size: 13px;
  text-align: right;
  color: var(--text-color-blue);
  text-decoration: none;
}

.logo {
  background-image: url('~@/assets/img/ukk-logo.svg') !important;
}
body.dark {
  .logo {
    background-image: url('~@/assets/img/ukk-logo-white.svg') !important;
  }

  .guest {
    color: #fff !important;
    background: var(--text-color-blue) !important;
  }
}
@media (prefers-color-scheme: dark) {
  .logo {
    background-image: url('~@/assets/img/ukk-logo-white.svg') !important;
  }

  .guest {
    color: #fff !important;
    background: var(--text-color-blue) !important;
  }
}
</style>