
import { IonPage, IonContent } from '@ionic/vue';

export default  {
  name: 'Landing',
  components: { 
    IonContent, 
    IonPage 
  },
  data () {
    return {
      email: '',
      password: '',
      sent: false
    }
  },
  computed: {
    submitted (): boolean {
      return (this as any).sent;
    },
    logo () {
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return require(`@/assets/img/ukk-logo${!prefersDarkMode ? '' : '-white'}.svg`)
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave landing');
    (this as any).sent = false;
  },
  ionViewDidEnter () {
    console.log('[ionView] entered landing');
    localStorage.setItem('firstVisit', 'true');
  }
}
